export const defaultEndPointDetails = process.env.REACT_APP_BASE_URL;
export var smartContractAddress = "";
export var ferrumNetworkIdentifier = "";
export var allowedNetwork;
export function setSmartContractAddress(address) {
  smartContractAddress = address;
}
export function setFerrumNetworkIdentifier(identifier) {
  ferrumNetworkIdentifier = identifier;
}
export function setAllowedNetwork(item) {
  allowedNetwork = item;
}
